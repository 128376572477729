
// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------
var frontDoor = document.getElementById("frontDoor");
var jsFloor1 = document.getElementById('floor1');
var jsFloor2 = document.getElementById('floor2');
var jsFloor3 = document.getElementById('floor3');
var jsFloor4 = document.getElementById('floor4');
var jsFloor5 = document.getElementById('floor5');
var jsFloor6 = document.getElementById('floor6');

function mq(device) {
  if (window.matchMedia("(max-width:768px)").matches && device === "sp") {
    return true;
  } else if (window.matchMedia("(min-width:769px)").matches && device === "pc") {
    return true;
  }
  return false;
}
// FUNCTIONS
function lazyLoadVideo() {
  
  window.scrollTo({
    top: jsFloor1.offsetTop,
    behavior: 'smooth'
  });
  var videoPc = document.getElementById("videoTop");
  var videoWrap = document.getElementById("videoWrap");
  var headerWrap = document.getElementById("headerWrap");
  var firstWrap = document.getElementById("firstWrap");
  var loadingWrap = document.getElementById("loadingWrap");
  var progressBar = document.getElementById("progressBar");

  var xhr = new XMLHttpRequest();
  xhr.open("GET", "/img/pc.mp4", true);
  xhr.responseType = "blob";
  console.log(xhr);
  xhr.addEventListener("progress", updateProgress);
  function updateProgress(oEvent) {
    if (oEvent.lengthComputable) {
      var percentComplete = (oEvent.loaded / oEvent.total) * 100;
      progressBar.style.width = percentComplete + "%";
      if (percentComplete === 100) {
        loadingWrap.classList.add("end");
      }
      console.log("percentComplete" + percentComplete);
    } else {
      // 全体の長さが不明なため、進捗情報を計算できない
    }
  }

  xhr.onload = function(e) {
    // console.log("this.status" + this.status);
    if (this.status === 200) {
      // ロード完了
      var blob = this.response;

      var src = (window.webkitURL || window.URL).createObjectURL(blob);
      console.log("videoPc loaded!!!");
      videoPc.src = "/img/pc.mp4";
      videoWrap.classList.add("show");
      videoPc.play();
      setTimeout(function() {
        firstWrap.classList.add("show");
        loadingWrap.classList.add("hide");
        headerWrap.classList.add("show");
      }, 1000);
    }
  };
  xhr.send();
}
 
function clickMoveFunc(){
  var topFloorLinks = document.querySelectorAll('.floor01-link');
  var jsMoves = document.querySelectorAll('.js-move');

  for(var i = 0; i < topFloorLinks.length; i += 1){
    topFloorLinks[i].addEventListener('click', function(e){
      for(var i = 0; i < jsMoves.length; i += 1){
        jsMoves[i].classList.remove("now");
      }
      e.preventDefault();
      var _f = e.currentTarget.getAttribute('data-floor');
      sessionStorage.setItem("floor_id", _f);
      if(sessionStorage.getItem("floor_id") === _f){
        jsMoves[_f].classList.add("now");
      }
      if(sessionStorage.getItem("now_floor_id") != _f){
        var destinationTop = document.getElementById("floor"+_f).offsetTop;
        frontDoor.classList.remove('show');
        frontDoor.classList.remove('hide');
  
        setTimeout(function(){
          window.scrollTo({
            top: destinationTop,
            behavior: 'smooth'
          });
        }, 500);
      }
      
     
    }, false);
  }

  for(var i = 0; i < jsMoves.length; i += 1){
    jsMoves[i].addEventListener('click', function(e){
      for(var i = 0; i < jsMoves.length; i += 1){
        jsMoves[i].classList.remove("now");
      }
      e.preventDefault();
      var _f = e.currentTarget.getAttribute('data-floor');
      sessionStorage.setItem("floor_id", _f);
      if(sessionStorage.getItem("floor_id") === _f){
        e.currentTarget.classList.add("now");
      }
      if(sessionStorage.getItem("now_floor_id") != _f){
        var destinationTop = document.getElementById("floor"+_f).offsetTop;
        frontDoor.classList.remove('show');
        frontDoor.classList.remove('hide');
  
        setTimeout(function(){
          window.scrollTo({
            top: destinationTop,
            behavior: 'smooth'
          });
        }, 500);
      }
      
     
    }, false);
  }
  
}
function tabFunc($tabParent, $tabChild){
  var tabParent = document.querySelectorAll($tabParent);
    var tabChild = document.querySelectorAll($tabChild);
    var hash = location.hash;
    
    if(hash){
      console.log(hash);
      var eIndex = hash.slice(1);
      console.log(eIndex);

      for (var i = 0; i < tabParent.length; i += 1) {
        tabParent[i].classList.remove("active");
        if (tabChild[i].getAttribute("data-index") === eIndex) {
          tabChild[i].classList.add("show");
          tabParent[i].classList.add("active");
        } else {
          tabChild[i].classList.remove("show");
        }
      }
    }
    for (var i = 0; i < tabParent.length; i += 1) {
      tabParent[i].addEventListener(
        "click",
        function(e) {
          var eIndex = e.currentTarget.getAttribute("data-index");
          for (var i = 0; i < tabParent.length; i += 1) {
            tabParent[i].classList.remove("active");
            if (tabChild[i].getAttribute("data-index") === eIndex) {
              tabChild[i].classList.add("show");
            } else {
              tabChild[i].classList.remove("show");
            }
          }

          e.currentTarget.classList.add("active");
          // console.log(e.currentTarget);
        },
        false
      );
    }
}
function topSpFunc() {
  var videoSp = document.getElementById("videoTop");
  var videoWrap = document.getElementById("videoWrap");
  var headerWrap = document.getElementById("headerWrap");
  var firstWrap = document.getElementById("firstWrap");
  var loadingWrap = document.getElementById("loadingWrap");
  var progressBar = document.getElementById("progressBar");
  var fLogo01 = document.getElementById("fLogo01");
  var fLogo02 = document.getElementById("fLogo02");
  var fLogo03 = document.getElementById("fLogo03");
  var menuTrigger = document.getElementById("menuTrigger");

  // 画像用配列
  var images = [
    "../sp-video.png"
  ];
  progressBar.classList.add("start");
  setTimeout(function(){
    loadingWrap.classList.add("end");
    initTop();
  }, 1800);
  // var testTimer;
  // var _setTime = 1;
  // function startTimer(){
  //   testTimer = setInterval(function(){
  //     if(_setTime === 100){
  //       stopTimer();
  //     }
  //     _setTime += 1;
  //     progressBar.style.width = _setTime + "%";
      
  //   } , 20);
  // }
  
  // function stopTimer(){
  //   clearInterval(testTimer);
  //   loadingWrap.classList.add("end");
  //   initTop();
  // }

  // startTimer();
  // 画像プリロード
 
  // for (i = 0; i < images.length; i++) {
  //   var img = document.createElement("img");
  //   img.src = images[i];
  //   console.log(i + "と" + images.length);

  //   if (i === images.length - 1) {
  //     console.log("finish");
  //   }
  // }
  function initTop() {
    console.log("in it");
    loadingWrap.classList.add("hide");

    setTimeout(function() {
      firstWrap.classList.add("show");
      loadingWrap.classList.add("hide");
      fLogo01.classList.add("show");
      fLogo02.classList.add("show");
      fLogo03.classList.add("show");
      headerWrap.classList.add("show");
    }, 1000);
  }
}

function subFunc() {
  var headerWrap = document.getElementById("headerWrap");
  var contentsWrap = document.getElementById("contentsWrap");
  headerWrap.classList.add("show");
  contentsWrap.classList.add("show");

  if ($("#itenSnapGallery").length) {

    $('#itenSnapGallery').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      gallery:{enabled:true}
      // other options
    });

  }

}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on("load", function() {
  // $(".sub-page .head-line").addClass("show");
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on("resize", function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log("script.js");

  var observer = lozad(".lozad", {
    threshold: [0, 1.0],
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.classList.add("loaded");
    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});

  if ($("#topPage").length) {
    clickMoveFunc();
    tabFunc('.tab-p-f6', '.tab-c-f6');
    tabFunc('.tab-p-f3', '.tab-c-f3');
    tabFunc('.tab-p-f2', '.tab-c-f2');


    if (mq("sp")) {
      // topSpFunc();
    } else {
      lazyLoadVideo();
    }
  } else {
    subFunc();
  }

  // SMOOTH SCROOL FOR CLASS NAME
  $("a.scroll").click(function() {
    console.log("TEST");
    $("html,body").animate({ scrollTop: $($(this).attr("href")).offset().top - 80 }, "slow", "swing");
    $("body").removeClass("menu-show");

    return false;
  });

  // SMOOTH SCROOL ATTRIBUTE NAME
  // $('a[href^="#"]').click(function() {
  //   var speed = 500;
  //   var href = $(this).attr("href");
  //   var target = $(href == "#" || href == "" ? "html" : href);
  //   var position = target.offset().top;
  //   $("html, body").animate({ scrollTop: position }, speed, "swing");
  //   return false;
  // });
  // MENU
  $("#menuTrigger").click(function() {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });

  $("#closeBtn").click(function() {
    $(".youtube-wrap").removeClass("show");
  });
  $("#youtubeBtn").click(function() {
    $(".youtube-wrap").addClass("show");
    return false;
  });

  // PC or SPサンプル
  if (mq("sp")) {
    console.log("Mobile");
  } else {
    console.log("Desktop");
  }
});

// SCROLL AND TOCH START ANIMATION
$(window).on("scroll", _.throttle(updatePosition, 250));

function updatePosition() {
  

  if ($(this).scrollTop() === jsFloor1.offsetTop) {
    frontDoor.classList.add("show");
    setTimeout(function(){
      frontDoor.classList.add("hide");
    },500);
    sessionStorage.setItem("now_floor_id", 1);

  }
  if ($(this).scrollTop() === jsFloor2.offsetTop) {
    frontDoor.classList.add("show");
    setTimeout(function(){
      frontDoor.classList.add("hide");
    },500);
    sessionStorage.setItem("now_floor_id", 2);
  }
  if ($(this).scrollTop() === jsFloor3.offsetTop) {
    frontDoor.classList.add("show");
    setTimeout(function(){
      frontDoor.classList.add("hide");
    },500);
    sessionStorage.setItem("now_floor_id", 3);
  }
  if ($(this).scrollTop() === jsFloor4.offsetTop) {
    frontDoor.classList.add("show");
    setTimeout(function(){
      frontDoor.classList.add("hide");
    },500);
    sessionStorage.setItem("now_floor_id", 4);
  }
  if ($(this).scrollTop() === jsFloor5.offsetTop) {
    frontDoor.classList.add("show");
    setTimeout(function(){
      frontDoor.classList.add("hide");
    },500);
    sessionStorage.setItem("now_floor_id", 5);
  }
  if ($(this).scrollTop() === jsFloor6.offsetTop) {
    frontDoor.classList.add("show");
    setTimeout(function(){
      frontDoor.classList.add("hide");
    },500);
    sessionStorage.setItem("now_floor_id", 6);
  }
 
  
}
